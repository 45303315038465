import Color from "ts-color-class";

export const colors = {
  primary: new Color("#134F71"),
  secondary: new Color("#3F3F3F"),
  lightGray: new Color("#D7D7D7"),
  gray: new Color("#838383"),
  white: new Color("#FFFFFF"),
  black: new Color("#000000")
};

