import { WmkLink } from "wmk-link";
import * as React from "react";
import { colors } from "../../ui/colors";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Typography } from "../../ui/Typography";
import { useState, useRef, useEffect } from "react";
import BurgerButton from "./BurgerButton";

const HeaderContainer = styled(Container)<{ mobile: string }>`
  row {
    padding: 1rem 0;
  }
  .link-row {
    justify-content: flex-end;
    height: 100%;
  }
  .image-col {
    img {
      width: 5rem;
    }
  }
  .link-col {
    justify-content: center;
    display: flex;
    align-items: center;
    h6 {
      text-align: center;
      font-weight: 600 !important;
      text-transform: uppercase;
    }
    a {
      color: ${colors.primary.getHex()};
      text-decoration: none;
    }
  }
  ${({ mobile }) =>
    mobile === "false"
      ? `.content-col {
          display: flex;
          justify-content: flex-end;
        }`
      : ``};
`;

const BurgerDrawer = styled.div<{ top: number }>`
  position: fixed;
  left: 0;
  height: calc(100vh);
  background: ${colors.primary.toString()};
  width: 100%;
  top: ${({ top }) => top}px;
  z-index: 1;
  .mobile-dropdown-col {
    a {
      color: ${colors.white.getHex()};
    }
    a:hover {
      color: ${colors.gray.getHex()};
    }
    text-align: center;
  }
`;

const DesktopMenu = ({ menu }: { menu: MenuQuery }) => {
  return (
    <Row className="link-row">
      {menu.links.map((link, i) => {
        return (
          <Col md={3} lg={2} className="link-col" key={link.title + i}>
            <Typography.H6>
              <WmkLink to={link.url.replace("self://", "/")}>
                {link.linkText}
              </WmkLink>
            </Typography.H6>
          </Col>
        );
      })}
    </Row>
  );
};

const MobileBurgerMenu = ({
  menu,
  height
}: {
  menu: MenuQuery;
  height: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Col
        xs="auto"
        style={{
          display: "flex",
          alignItems: "center",
          padding: `0 2vh 0 0`
        }}>
        <div style={{ padding: `0` }}>
          <BurgerButton isStuck={false} isOpen={isOpen} toggle={toggle} />
        </div>
      </Col>
      {isOpen ? (
        <BurgerDrawer top={height}>
          <Container style={{ padding: `5vh 1vh` }}>
            <Row className="flex-column">
              {menu.links.map((link, i) => {
                const { title } = link;
                const target = link.target === "New Window" ? "blank" : "self";
                return (
                  <Col className="mobile-dropdown-col" key={title + i}>
                    <Typography.H2>
                      <WmkLink
                        key={title + i}
                        to={link.url.replace("self://", "/")}
                        target={target}
                        onClick={close}>
                        {link.linkText}
                      </WmkLink>
                    </Typography.H2>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </BurgerDrawer>
      ) : null}
    </>
  );
};

const Header = () => {
  const [viewWidth, setViewWidth] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
    const target = headerRef.current?.getBoundingClientRect();
    setHeaderHeight(target?.height ? target.height : 0);
  };

  const data: HeaderQuery = useStaticQuery(query);
  const logoImage = new Img(data.logo);
  return (
    <header
      style={{ background: colors.lightGray.getHex(), padding: "1rem 0" }}
      ref={headerRef as any}>
      <HeaderContainer mobile={viewWidth > 767 ? "true" : "false"}>
        <Row>
          <Col className="image-col" xs={4} md={3}>
            <WmkImage image={logoImage} />
          </Col>
          <Col className="content-col">
            {viewWidth > 767 ? (
              <DesktopMenu menu={data.menu} />
            ) : (
              <MobileBurgerMenu menu={data.menu} height={headerHeight} />
            )}
          </Col>
        </Row>
      </HeaderContainer>
    </header>
  );
};

export default Header;

interface HeaderQuery {
  logo: ContentfulImageQuery;
  menu: MenuQuery;
}

const query = graphql`
  {
    menu: contentfulMenu(title: { eq: "Main Menu" }) {
      ...NodeMenuFields
    }
    logo: contentfulAsset(title: { eq: "DMMG-logo-color-wo-manufacturing" }) {
      ...NodeImageQuery
    }
  }
`;
