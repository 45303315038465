import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Copyright } from "wmk-layout";
import { WmkLink } from "wmk-link";
import { MenuQuery } from "../../fragments/NodeMenuFields";
import { SiteMetaDataFields } from "../../fragments/NodeSiteMetadataFields";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn
} from "react-icons/fa";

const FooterWrapper = styled.footer`
  background: ${colors.secondary.getHex()};
  color: ${colors.white.getHex()};
  .socials-col {
    .row {
      justify-content: flex-end;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .image-col {
    img {
      height: 5rem;
      width: 5rem;
    }
  }
  .social-col {
    width: fit-content;
    h6 {
      font-size: 26px !important;
    }
  }
  .top-row {
    padding: 3rem 0;
  }
  .link-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      width: fit-content;
    }
    a {
      color: ${colors.white.getHex()};
    }
  }
  .wmk-layout-copyright {
    border-top: 2px solid ${colors.gray.getHex()};
    padding: 1rem 0 2rem;
  }
  @media only screen and (max-width: 767px) {
    .link-col {
      order: 1;
      align-items: center;
      padding-bottom: 1rem;
    }
    .socials-col {
      order: 3;
      align-items: center;
      padding-top: 1rem;
    }
    .image-col {
      order: 2;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
    }
    .social-col {
      h6 {
        font-size: 50px !important;
      }
    }
  }
`;

interface PlatformInterface {
  [k: string]: React.ReactElement;
}

const Footer = () => {
  const data: FooterQuery = useStaticQuery(query);
  const logoImage = new Img(data.logo);
  const platforms: PlatformInterface = {
    facebook: <FaFacebookF />,
    twitter: <FaTwitter />,
    youtube: <FaYoutube />,
    linkedin: <FaLinkedinIn />
  };

  return (
    <FooterWrapper>
      <Container>
        <Row className="top-row">
          <Col className="image-col" xs={12} md={3}>
            <WmkImage image={logoImage} />
          </Col>
          <Col className="socials-col" xs={12} md={6}>
            <Row>
              {data.social?.links &&
                data.social?.links.length &&
                data.social.links.map((link, i) => {
                  const { title, url } = link;
                  const target =
                    link.target === "New Window" ? "blank" : "self";
                  return (
                    <Col className="social-col" xs={6} key={link.title + i}>
                      <WmkLink
                        key={title}
                        to={url}
                        target={target}
                        title={"Visit " + title + " page"}>
                        <Typography.H6 style={{ color: "white" }}>
                          {platforms[link.linkText.toLocaleLowerCase()]}
                          <span className="sr-only sr-only-focusable visually-hidden visually-hidden-focusable">
                            {"Visit " + title.toLocaleLowerCase() + " page"}
                          </span>
                        </Typography.H6>
                      </WmkLink>
                    </Col>
                  );
                })}
            </Row>
            {/* <SocialMediaIcons
              socials={socials.links}
              platforms={platforms}
            /> */}
          </Col>
          <Col className="link-col" xs={12} md={3}>
            <Typography.P>Address: 2 Arnolt Dr. Pierceton, IN 46562</Typography.P>
            <Typography.P>
              {"Phone: "}
              <WmkLink tel>(574) 594-5544</WmkLink>
            </Typography.P>
            <Typography.P>
              {"Email: "}
              <WmkLink mailto>inquire@choosedmmg.com</WmkLink>
            </Typography.P>
          </Col>
        </Row>
        <Row>
          <Col className="copyright-col">
            <Copyright
              style={{ borderTop: `2px solid ${colors.gray.getHex()} ` }}>
              {data.site.siteMetadata.title}, LLC. All rights reserved.
            </Copyright>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;

interface FooterQuery {
  site: SiteMetaDataFields;
  logo: ContentfulImageQuery;
  social: MenuQuery;
}

const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    logo: contentfulAsset(title: { eq: "DMMG logo grey" }) {
      ...NodeImageQuery
    }
    social: contentfulMenu(title: { eq: "Social Media" }) {
      ...NodeMenuFields
    }
  }
`;
